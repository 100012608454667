import React, { useReducer, createContext, useState, useEffect } from "react"
import serviceData from "./serviceData"
import useZohoId from "../components/custom-hooks/useZohoId"

const initialState = {
  category: "",
  price: "",
  additionalPrice: "",
  service: "",
  dateTime: "",
  startDate: "",
  endDate: "",
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  birthDate: "",
  details: "",
  serviceId: "",
  staffId: "",
  slots: null,
  extras: [],
  paymentExtras: null,
  collaborativeServiceId: null,
  formId: null,
  slot_length: "",
  extrasFullArray: [],
  dialCode: "",
  countryCode: "",
}

const bookingReducer = (state, action) => {
  switch (action.type) {
    case "ADD_VALUE":
      return {
        ...state,
        [action.item]: action.payload,
      }
    case "REMOVE_VALUE":
      const newState = { ...state }
      delete newState[action.payload]
      return newState

    case "POPULATE_FROM_LOCAL_STORAGE":
      return {
        ...state,
        ...action.payload,
      }

    case "RESET_DATA":
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}

export const AppContext = createContext(initialState)

export const AppointmentContext = ({ children }) => {
  useZohoId()

  const isBrowser = typeof window !== "undefined"
  const initialDeposit = 5000
  const [dateRange, setDateRange] = useState(null)
  const [choice, setChoice] = useState(initialDeposit)
  const [chosenCategory, setChosenCategory] = useState(null)

  const [userViewedCookie, setUserViewedCookie] = useState(false)

  const isFullPayment = Number(choice) !== initialDeposit

  const [bookingState, dispatchBooking] = useReducer(
    bookingReducer,
    initialState
  )
  // console.log(bookingState, "bookingState")

  const [appointmentID, setAppointmentID] = useState("")

  const addData = (item) => {
    dispatchBooking({ type: "ADD_VALUE", ...item })
  }

  const createDateRange = (inputDate) => {
    const date = new Date(inputDate)
    const [year, month] = [date.getFullYear(), date.getMonth() + 1]
    const firstDay = `${year}-${String(month).padStart(2, "0")}-01`
    const lastDay = new Date(year, month, 0).toISOString().split("T")[0]
    setDateRange(`&start_date=${firstDay}&end_date=${lastDay}`)
  }

  //BOOKING PAGES

  const [incomingData, setIncomingData] = useState(serviceData.data)
  const [categories, setCategories] = useState([])
  const [services, setServices] = useState([])
  // const [services, setServices] = useState(
  //   (Array.isArray(incomingData) &&
  //     incomingData?.map((el) => el?.title).filter(Boolean)) ||
  //     []
  // )

  const [selectedCategory, setSelectedCategory] = useState("")
  const [selectedService, setSelectedService] = useState("")
  const [selectedServiceId, setSelectedServiceId] = useState("")
  const [isServiceSelected, setIsServiceSelected] = useState(false)
  const [priceChoice, setPriceChoice] = useState("deposit")

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    confirmEmail: "",
    birthMonth: "",
    birthDay: "",
    birthYear: "",
    details: "",
    dialCode: "44",
    countryCode: "gb",
  })

  // console.log(bookingState, "bookingState")
  // console.log(formData, "formData")

  const handleAddCategory = (item) => {
    if (selectedCategory !== "" || item !== selectedCategory) {
      setSelectedService("")
    }
    setSelectedCategory(item)
    addData({ item: "category", payload: item })
  }

  // const handleGetData = async () => {
  //   const response = await axios.post("/api/getService", {
  //     booklyUrl: "wpo_bookly_services",
  //   })

  //   if (response?.data?.data && Array.isArray(response.data.data)) {
  //     console.log(response.data.data)
  //     setIncomingData(response.data.data)
  //   } else {
  //     setIncomingData([])
  //   }
  // }

  // useEffect(() => {
  //   handleGetData()
  // }, [])

  const addFirstIteraction = () => {
    if (isBrowser) {
      const firstIntraction = localStorage.getItem("firstIntraction")

      if (!firstIntraction) {
        localStorage.setItem("firstIntraction", true)
      }
    }
  }

  const removeFirstIteraction = () => {
    if (isBrowser) {
      localStorage.removeItem("firstIntraction")
    }
  }

  const checkFirstIteraction = () => {
    if (isBrowser) {
      const firstIntraction = localStorage.getItem("firstIntraction")

      if (firstIntraction) {
        return true
      } else {
        return false
      }
    }
  }

  const handleAddService = (item) => {
    if (!item) return

    let slelectedService

    if (item?.title) {
      slelectedService = item
    } else {
      slelectedService = services.find((serv) => serv.id === item)
    }

    setIsServiceSelected(true)
    setSelectedService(slelectedService.title)
    setSelectedServiceId(slelectedService.id)
    setSelectedCategory(slelectedService.category)
    addData({ item: "service", payload: slelectedService.title })
    addData({ item: "price", payload: slelectedService.price })
    addData({ item: "serviceId", payload: slelectedService.id })
    // addData({ item: "category", payload: item })
    addData({ item: "slot_length", payload: slelectedService.slot_length })
  }

  const handleRemoveService = () => {
    setIsServiceSelected(false)
    setSelectedService("")
    setSelectedServiceId("")
    setSelectedCategory("")
    addData({ item: "service", payload: "" })
    addData({ item: "price", payload: "" })
    addData({ item: "serviceId", payload: "" })
    addData({ item: "slot_length", payload: "" })
    setActiveService(false)
    removeFirstIteraction()
  }

  const resetCalendar = () => {
    setDateRange(null)
    addData({ item: "endDate", payload: "" })
    addData({ item: "startDate", payload: "" })
    addData({ item: "slots", payload: null })
    addData({ item: "extras", payload: [] })
    addData({ item: "paymentExtras", payload: null })
    addData({ item: "additionalPrice", payload: "" })
    addData({ item: "staffId", payload: "" })
    addData({ item: "collaborativeServiceId", payload: null })
    addData({ item: "extrasFullArray", payload: [] })
    setActiveService(false)
    removeFirstIteraction()
  }

  const [extras, setExtras] = useState(null)
  const [activeService, setActiveService] = useState(false)

  const resetBookingData = () => {
    if (isBrowser) {
      if (bookingState.service || bookingState.category) {
        localStorage.removeItem("bookingState")
        dispatchBooking({ type: "RESET_DATA" })
        setServices([])
        setCategories([])
        setDateRange(null)
        setChoice(initialDeposit)
        setChosenCategory(null)
        setSelectedCategory("")
        setSelectedService("")
        setSelectedServiceId("")
        setIsServiceSelected(false)
        setAppointmentID("")
        setPriceChoice("deposit")
        setFormData({
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          confirmEmail: "",
          birthMonth: "",
          birthDay: "",
          birthYear: "",
          details: "",
        })
        setExtras(null)
        setActiveService(false)
        removeFirstIteraction()
      }
    }
  }

  const resetZohoId = () => {
    localStorage.removeItem("zohoId")
  }

  return (
    <AppContext.Provider
      value={{
        bookingState,
        initialState,
        addData,
        dispatchBooking,
        choice,
        setChoice,
        appointmentID,
        setAppointmentID,
        createDateRange,
        dateRange,
        isFullPayment,
        setDateRange,
        userViewedCookie,
        setUserViewedCookie,
        chosenCategory,
        setChosenCategory,
        //Booking pages new data
        resetCalendar,
        incomingData,
        selectedCategory,
        selectedService,
        handleAddCategory,
        handleAddService,
        categories,
        setCategories,
        services,
        setServices,
        isServiceSelected,
        selectedServiceId,
        //slot
        createDateRange,
        dateRange,
        setDateRange,
        //addons
        extras,
        setExtras,
        activeService,
        setActiveService,
        //details
        formData,
        setFormData,
        //payment
        priceChoice,
        setPriceChoice,
        //reset
        resetBookingData,
        handleRemoveService,
        //firstInteraction
        addFirstIteraction,
        removeFirstIteraction,
        checkFirstIteraction,
        resetZohoId,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
