import { useEffect } from "react"

const useZohoId = () => {
  const isBrowser = typeof window !== "undefined"

  const generateRandomString = () => {
    const length = Math.floor(Math.random() * (50 - 30 + 1)) + 30
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    let result = ""

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      result += characters.charAt(randomIndex)
    }

    return result
  }

  useEffect(() => {
    if (isBrowser) {
      const hasZohoId = localStorage.getItem("zohoId")
      if (hasZohoId) return

      const urlParams = new URLSearchParams(window.location.search)
      const gclid = urlParams.get("gclid")

      const zohoId = JSON.stringify({
        google_id: gclid ? gclid : "",
        session_id: generateRandomString(),
        email: "",
        lead_id: "",
      })
      localStorage.setItem("zohoId", zohoId)
    }
  }, [])

  return
}

export default useZohoId
