import React, { useState, createContext } from "react";

const defaultState = {
    stripePromise: null,
    setStripePromise: () => { },
    setClientSecret: () => { },
    clientSecret: null,
}

export const Context = createContext(defaultState);

export const StripeContext = ({ children }) => {

    const [stripePromise, setStripePromise] = useState(null)
    const [clientSecret, setClientSecret] = useState(null)

    return (
        <Context.Provider
            value={{
                stripePromise,
                setStripePromise,
                setClientSecret,
                clientSecret,
            }}
        >
            {children}
        </Context.Provider>
    )

}